import '../sass/7-bundles/index.scss'

async function loadStyles() {
  import('../sass/7-bundles/index.scss')
}

async function loadLava() {
  import('../experience/lava.js')
}

async function loadParticles() {
  import('../experience/particles.js')
}

async function loadMedida() {
  import('../scripts/medida.js')
}

async function loadPreloader() {
  import('../scripts/preloader.js')
}

window.addEventListener('load', async function loadModules() {
  // await loadStyles()
  await loadLava()
  await loadParticles()
  await loadMedida()
  await loadPreloader()
})

